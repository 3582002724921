import React, {useContext, useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import {makeStyles} from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import {participationsLookup} from '../../main/specificUtils/configs'
import CircularProgress from '@material-ui/core/CircularProgress'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
    PayPalButtons,
    PayPalCardFieldsProvider,
    PayPalCVVField,
    PayPalExpiryField,
    PayPalNameField,
    PayPalNumberField,
    usePayPalCardFields,
    usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import {AppContext} from 'contexts/AppContext'
import GooglePayButton from "@google-pay/button-react";
import useExternalScripts from "../../hooks/useExternalScripts";
import {isInDebugMode} from "../../commonUtils/misc";
import {useMediaQuery} from "react-responsive";

// PayPal
const currency = "EUR";

const standardCardFieldStyle = {
    input: {
        'border-radius': '28px',
    }
}


const ApplePayButton = ({onClick}) => {


    useEffect(
        () => {
            const btn = document.getElementById("btn-appl");
            btn.addEventListener("click", onClick);
            return () => {
                if (btn) {
                    btn.removeEventListener("click", onClick);
                }
            };

        }, [onClick]
    );

    return (
        <apple-pay-button
            id="btn-appl"
            buttonstyle="black"
            type="buy"
            locale="it"
        ></apple-pay-button>
    );
};

const SubmitPayment = ({isPaying, setIsPaying}) => {
    const {cardFieldsForm, fields} = usePayPalCardFields();

    const handleClick = async () => {
        if (!cardFieldsForm) {
            const childErrorMessage =
                "Unable to find any child components in the <PayPalCardFieldsProvider />";

            throw new Error(childErrorMessage);
        }
        const formState = await cardFieldsForm.getState();

        if (!formState.isFormValid) {
            return alert("The payment form is invalid");
        }
        setIsPaying(true);

        cardFieldsForm.submit().catch((err) => {
            setIsPaying(false);
        });
    };

    return (
        <Button
            variant="contained"
            color="primary"
            disabled={isPaying}
            onClick={handleClick}
        >
            {isPaying ? <CircularProgress/> : "Paga"}
        </Button>
    );
};

const useStyles = makeStyles(theme => ({
    payButton: {
        margin: theme.spacing(2),
    },
    bankTransferDesc: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    otherPayment: {
        margin: theme.spacing(1),
    },
    expandedDetail: {
        display: 'flex',
        flexDirection: 'column',
        // marginBottom: theme.spacing(1),
    },
    expansionPanel: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        zIndex: 2000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}))

export default function Payment(props) {
    const isMobile = useMediaQuery({ maxWidth: 600 });
    const {loaded: applePayLoaded} = useExternalScripts({
        url: "https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js",
    });
    const [appState] = useContext(AppContext)
    const [isPaying, setIsPaying] = useState(false);
    const classes = useStyles()
    const [loading, setLoading] = useState(true)
    const [allowedPaymentMethods, setAllowedPaymentMethods] = useState(null)
    const [showApplePayButton, setShowApplePayButton] = useState(false)
    const [applePayConfig, setApplePayConfig] = useState(null)
    const [merchantInfo, setMerchantInfo] = useState(null)
    const [{isResolved}] = usePayPalScriptReducer();
    const {
        payError,
        paying,
        paid,
        onFinalizeBankTransferOrder,
        onBankTransfer,
        bankTransfer,
        step2Participation,
        division,
        paypalClientId,
        onMarkAsPaymentError,
        paypalAnswered,
        onPaypalAnswered,
        onCreateOrder,
        onCaptureOrder,
        amount,
    } = props
    const [expanded, setExpanded] = useState(bankTransfer)


    useEffect(() => {
        if (isResolved) {
            configGPay();

            if (applePayLoaded) {
                configAPay();
            }
        }
    }, [isResolved, applePayLoaded]);

    const handleApplePay = () => {
        console.info("Apple Pay Button Clicked");
        //Controllo nuovamente che ApplePay sia supportato
        if (!window.ApplePaySession || !window.ApplePaySession.canMakePayments()) {
            console.error("Apple Pay non è supportato su questo dispositivo.");
            onError("Apple Pay non è supportato su questo dispositivo.");
            return;
        }
        const paymentRequest = {
            countryCode: applePayConfig.countryCode,
            currencyCode: currency,
            supportedNetworks: applePayConfig.supportedNetworks,
            merchantCapabilities: applePayConfig.merchantCapabilities,
            requiredShippingContactFields: ['name', 'phone', 'email', 'postalAddress'],
            requiredBillingContactFields: ['postalAddress'],
            total: {
                label: "Totale",
                type: 'final',
                amount: amount,
            },
        };

        const APPLE_PAY_VERSION = 4; //Indica la versione di Apple Pay che il sito supporta. Doc: https://developer.apple.com/documentation/apple_pay_on_the_web/apple_pay_on_the_web_version_history
        const session = new window.ApplePaySession(APPLE_PAY_VERSION, paymentRequest);
        const applepay = window.paypal.Applepay();

        session.onvalidatemerchant = (event) => {
            applepay.validateMerchant({
                validationUrl: event.validationURL
            }).then(validateResult => {
                session.completeMerchantValidation(validateResult.merchantSession);
            }).catch(validateError => {
                console.error(validateError);
                session.abort();
                onError(validateError);
            });
        };

        session.onpaymentauthorized = async (event) => {
            try {
                const orderID = await onCreateOrder(amount);
                console.info("Ordine creato con successo:", orderID);

                const confirmResult = await applepay.confirmOrder({
                    orderId: orderID,
                    token: event.payment.token,
                    billingContact: event.payment.billingContact,
                });

                console.info(confirmResult);
                const status = confirmResult.approveApplePayPayment?.status;

                if (status === "APPROVED" || status == "APPROVED") {
                    session.completePayment(window.ApplePaySession.STATUS_SUCCESS);
                    const captureResult = await onCaptureOrder(orderID);
                    console.info("Pagamento catturato con successo:", captureResult);
                } else {
                    console.error("Errore durante la conferma dell'ordine.");
                    session.completePayment(window.ApplePaySession.STATUS_FAILURE);
                    onError("Errore durante la conferma dell'ordine.");
                }
            } catch (error) {
                console.error("Errore nel processo di pagamento Apple Pay:", error);
                session.completePayment(window.ApplePaySession.STATUS_FAILURE);
                onError(error);
            }
        };
        session.begin();
    };


    const configGPay = async () => {
        const googlePayConfig = await window.paypal.Googlepay().config();
        console.info('GooglePay Config:', googlePayConfig);
        setAllowedPaymentMethods(googlePayConfig.allowedPaymentMethods);
        setMerchantInfo(googlePayConfig.merchantInfo);
    }

    const configAPay = async () => {
        if (!window.ApplePaySession || !window.ApplePaySession.canMakePayments) {
            console.error('Il dispositivo non supporta Apple Pay');
        }
        const applepay = window.paypal.Applepay();
        applepay.config()
            .then(applepayConfig => {
                console.info('ApplePay Config:', applepayConfig);
                if (applepayConfig.isEligible) {
                    setShowApplePayButton(true);
                    setApplePayConfig(applepayConfig);
                } else {
                    setShowApplePayButton(false);
                    console.error('Apple Pay non è abilitato');
                }
            })
            .catch(applepayConfigError => {
                console.error('Errore durante il fetching di Apple Pay. ->' + applepayConfigError);
            });

    }


    function onApprove(data) {
        console.info('Order Approved:', data.orderID);
        return onCaptureOrder(data.orderID).then((details) => {
            console.info('Payment Successful:', details);
            onPaypalAnswered(true);
        }).catch((error) => {
            console.error("Error capturing order:", error);
            onError(error);
        });
    }

    function onCreate() {
        return onCreateOrder(amount).then((orderID) => {
            console.info('Order Created:', orderID);
            return orderID;
        });
    }

    function onError(err) {
        console.log({onError: err})
        onPaypalAnswered(true)
        onMarkAsPaymentError(err)
    }

    const handleChange = event => {
        const {checked} = event.target
        if (checked) {
            setExpanded(true)
        }
        onBankTransfer(checked)
    }

    const handleExpansion = (event, expandedState) => {
        setExpanded(expandedState)
    }
    // type of text
    let textBankTransfer = {
        iban: '',
        bic: '',
    }

    const inpsPractice = (
        step2Participation === participationsLookup.INPSIEME ||
        step2Participation === participationsLookup.INPSNUOVO
    )

    if (division === '1') {
        // GSTUDY
        if (
            step2Participation === participationsLookup.INPSIEME ||
            step2Participation === participationsLookup.INPSNUOVO
        ) {
            textBankTransfer = {
                /*title: 'Bonifico ordinario (il tuo posto è bloccato, effettua il bonifico entro 5 giorni per confermarlo)',
                title:
                'Clicca qui per confermare e invia la prenotazione',*/
                title:
                    'Bonifico ordinario',
                iban: 'IT23Y0306913507100000007395 (Banca Intesa San Paolo)',
                bic: 'BCITITMM',
            }
        } else if (step2Participation === participationsLookup.PRIVATO) {
            textBankTransfer = {
                /*title: 'Bonifico ordinario da effettuare (riceverai una mail con tutti gli estremi e le indicazioni per procedere al pagamento)',
                title:
                'Clicca qui per confermare e invia la prenotazione',*/
                title:
                    'Bonifico ordinario',
                iban: 'IT23Y0306913507100000007395 (Banca Intesa San Paolo)',
                bic: 'BCITITMM',
            }
        } else if (
            step2Participation === participationsLookup.AZIENDA_CONVENZIONATA
        ) {
            textBankTransfer = {
                /*title: 'Bonifico ordinario (riceverai tutti gli estremi e le indicazioni per procedere al pagamento)',
                  title:
                'Clicca qui per confermare e invia la prenotazione',*/
                title:
                    'Bonifico ordinario',
                iban: 'IT12V0311113501000000004631 (Ubi Banca)',
                bic: 'BLOPIT22',
            }
        }
    } else if (division === '2') {
        // LME
        if (
            step2Participation === participationsLookup.INPSIEME ||
            step2Participation === participationsLookup.INPSNUOVO
        ) {
            textBankTransfer = {
                //title: 'Bonifico ordinario (riceverai tutti gli estremi e le indicazioni per procedere al pagamento)',
                title:
                    'Bonifico ordinario',
                iban: 'IT02F0306913507100000005144 (Banca Intesa San Paolo)',
                bic: 'BCITITMM',
            }
        } else if (step2Participation === participationsLookup.PRIVATO) {
            textBankTransfer = {
                //title: 'Bonifico ordinario da effettuare (riceverai una mail con tutti gli estremi e le indicazioni per procedere al pagamento)',
                title:
                    'Bonifico ordinario ',
                iban: 'IT76O0103013500000000911744 (Banca Monte Paschi Siena)',
                bic: 'PASCITMMASP',
            }
        } else if (
            step2Participation === participationsLookup.AZIENDA_CONVENZIONATA
        ) {
            textBankTransfer = {
                //title: 'Bonifico ordinario (riceverai tutti gli estremi e le indicazioni per procedere al pagamento)',
                title:
                    'Bonifico ordinario',
                iban: 'IT76O0103013500000000911744 (Banca Monte Paschi Siena)',
                bic: 'PASCITMMASP',
            }
        }
    } else if (division === '3') {
        textBankTransfer = {
            /*title: 'Bonifico ordinario (riceverai tutti gli estremi e le indicazioni per procedere al pagamento)',*/
            title:
                'Bonifico ordinario',
            iban: 'IT23Y0306913507100000007395 (Banca Intesa San Paolo)',
            bic: 'BCITITMM',
        }
    }

    const paypalMin = 30
    let paypalMax = 500
    if (division === '2') paypalMax = 300

    const isMain = false //(appState.applicationType === 'main')
    return (
        <>
            {paying && (
                <div className={classes.overlay}>
                    <CircularProgress />
                </div>
            )}
            {!paid && (
                <Grid container spacing={3} className="panel">
                    {/*Il blocco del bonifico non dev'essere visualizzato se il form è il main*/}
                    {!paypalAnswered && !isMain && ( /* ripensamento Giocamondo: visualizzare i bonifici anche per flusso INPS */
                        <>
                            <Grid item xs={12} sm={1}></Grid>
                            <Grid item xs={12} sm={10} className="payment-wrapper">
                                <>
                                    <ExpansionPanel
                                        className={classes.expansionPanel}
                                        expanded={expanded}
                                        onChange={handleExpansion}>
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                            aria-label="Expand"
                                            aria-controls="additional-actions1-content"
                                            id="additional-actions1-header">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={bankTransfer}
                                                        onChange={handleChange}
                                                        disabled={paying}
                                                        value="primary"
                                                        inputProps={{'aria-label': 'primary checkbox'}}
                                                    />
                                                }
                                                label={textBankTransfer.title}
                                            />
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails className={classes.expandedDetail}>
                                            <div align-items="center">
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={() => onFinalizeBankTransferOrder()}
                                                    className={classes.payButton}
                                                    disabled={!bankTransfer || paying}>
                                                    Invia Prenotazione
                                                </Button>
                                            </div>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </>
                            </Grid>
                            <Grid item xs={12} sm={1}></Grid>
                        </>
                    )}

                    {!bankTransfer && !!paypalClientId && (
                        <>
                            <Grid item xs={12} sm={1}></Grid>
                            <Grid
                                item
                                xs={12}
                                sm={10}
                                className="payment-wrapper"
                                align-items="center">
                                {!bankTransfer && !paypalAnswered && !isMain && (
                                    <Typography variant="h6" className={classes.otherPayment}>
                                        Oppure conferma immediatamente con:
                                    </Typography>
                                )}
                                {isMain && <hr/>}
                                <Paper variant="outlined"
                                       style={{
                                           width: "100%",
                                           ...(isMobile
                                               ? {}
                                               : {minWidth: "500px", padding: "20px"}),
                                           maxWidth: "750px",
                                           margin: "0 auto",
                                       }}
                                >
                                    {showApplePayButton && <ApplePayButton onClick={handleApplePay}/>}
                                    {showApplePayButton && <br/>}
                                    {merchantInfo && allowedPaymentMethods && <GooglePayButton
                                        onCancel={() => console.log('Google Pay Cancelled')}
                                        onError={err => onError(err)}
                                        style={{
                                            width: "100%",
                                            ...(isMobile
                                                ? {}
                                                : {minWidth: "500px"}),
                                            maxWidth: "750px",
                                            height: "55px",
                                            minHeight: "40px",
                                            maxHeight: "55px",
                                            marginBottom: "17px",
                                        }}
                                        onPaymentAuthorized={async paymentResponse => {
                                            console.info('Payment Authorised:', paymentResponse);
                                            const id = await onCreateOrder(amount);
                                            const {status} = await window.paypal.Googlepay().confirmOrder({
                                                orderId: id,
                                                paymentMethodData: paymentResponse.paymentMethodData,
                                            })
                                            if (status === "APPROVED") {
                                                const captureResponse = await onCaptureOrder(id);
                                                console.log({captureResponse});
                                                onPaypalAnswered(true);
                                                return {transactionState: "SUCCESS"};
                                            } else {
                                                onError("Errore durante la conferma dell'ordine");
                                                return {
                                                    transactionState: "ERROR",
                                                    error: {message: "Errore durante la conferma dell'ordine"}
                                                };
                                            }
                                        }}
                                        buttonRadius={28}
                                        environment={isInDebugMode ? "TEST" : "PRODUCTION"}
                                        buttonLocale={"it"}
                                        buttonSizeMode={"fill"}
                                        onReadyToPayChange={result => {
                                            console.info('Ready to Pay:', result);
                                        }}
                                        paymentRequest={{
                                            apiVersion: 2,
                                            apiVersionMinor: 0,
                                            allowedPaymentMethods: allowedPaymentMethods,
                                            merchantInfo: merchantInfo,
                                            transactionInfo: {
                                                totalPriceStatus: 'FINAL',
                                                totalPriceLabel: 'Totale',
                                                totalPrice: amount,
                                                currencyCode: currency,
                                            },
                                            callbackIntents: ['PAYMENT_AUTHORIZATION'],
                                        }}
                                        onLoadPaymentData={paymentData => {
                                            console.log('load payment data', paymentData);
                                        }}
                                    />}
                                    <PayPalButtons
                                        createOrder={(_) => onCreate()}
                                        onApprove={(data) => onApprove(data)}
                                        onError={onError}
                                        onCancel={data => console.log({onCancel: data})}
                                        style={{
                                            shape: "pill",
                                            layout: "vertical",
                                            color: "gold",
                                            label: "paypal",
                                        }}
                                    />
                                    <PayPalCardFieldsProvider
                                        onError={onError}
                                        createOrder={() => onCreate()}
                                        onApprove={(data) => onApprove(data)}
                                        onCancel={data => console.log({onCancel: data})}
                                    >
                                        <PayPalNameField style={standardCardFieldStyle}/>
                                        <PayPalNumberField style={standardCardFieldStyle}/>
                                        <PayPalExpiryField style={standardCardFieldStyle}/>
                                        <PayPalCVVField style={standardCardFieldStyle}/>
                                        <SubmitPayment
                                            isPaying={isPaying}
                                            setIsPaying={setIsPaying}
                                        />
                                    </PayPalCardFieldsProvider>
                                    {paypalAnswered && <CircularProgress/>}
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={1}></Grid>
                        </>
                    )}
                </Grid>
            )}
            {paid && !payError && (
                <div> Operazione di pagamento effettuata con successo </div>
            )}
        </>
    )
}

Payment.propTypes = {
    //state: PropTypes.object.isRequired,
    //billingAddress: PropTypes.object.isRequired,
    amount: PropTypes.string.isRequired,
    // email: PropTypes.string.isRequired,
    payError: PropTypes.any,
    paying: PropTypes.bool,
    paid: PropTypes.bool,
    onFinalizeBankTransferOrder: PropTypes.func.isRequired,
    onBankTransfer: PropTypes.func.isRequired,
    bankTransfer: PropTypes.bool,
    step2Participation: PropTypes.string,
    division: PropTypes.string,
    paypalClientId: PropTypes.string,
    onPaypalPaidOrder: PropTypes.func.isRequired,
    onMarkAsPaymentError: PropTypes.func.isRequired,
}
