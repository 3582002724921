import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import clsx from 'clsx'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import {
    bonusTypes,
    EMPTY_INPS_VALUE,
    participationsLookup,
} from '../../specificUtils/configs'
import Coupon from './Coupon'
import Voucher from './Voucher'
import {FormGroup, MenuItem, Radio, RadioGroup} from '@material-ui/core'
import {Alert, AlertTitle} from '@material-ui/lab'
import moment from "moment";
import FormLabel from "@material-ui/core/FormLabel";


// Funzione per calcolare l'età con gestione del formato YYYY-MM-DD
function calculateAge(birthDate) {
    if (!birthDate) return 0; // Se la data non è valida, ritorna 0
    
    // Se la data è fornita solo come 'YYYY', la convertiamo in 'YYYY-01-01'
    if (/^\d{4}$/.test(birthDate)) {
        birthDate += '-01-01'; // Trasforma '2000' in '2000-01-01'
    }
    
    // Creazione di oggetti Date per la data corrente e la data di nascita
    const today = new Date();
    const birth = new Date(birthDate);
    
    // Calcolo dell'età di base (differenza di anni)
    let age = today.getFullYear() - birth.getFullYear();
    
    // Controllo se il compleanno non è ancora passato quest'anno
    const monthDiff = today.getMonth() - birth.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
        age--;
    }
    
    return age;
}

const useStyles = makeStyles(theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    textField4: {
        width: '32.333333%',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    textField6: {
        width: '46.666%',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    textField12: {
        width: '96%',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(3),
    },
    menu: {
        width: 200,
    },
}))

export default function StepBonusInpsieme(props) {
    const {
        division,
        inpsServices,
        participations,
        step1ChildBirthDate,
        step1ChildFiscalCode,
        step2Participation,
        step2InpsServiceId,
        step2Grade,
        step2Class,
        step2InpsServiceFlag,
        step2bonuses,
        step2QCodiceCultura,
        step2BonusRequested,
        step2BonusSurname,
        step2BonusName,
        onEventChange,
        onSimpleCheckboxChange,
        onBonusRequest,
        step2Coupon,
        step2VoucherCode,
        step2VoucherCodes,
        step2INPSnewQuestion,
        fixedPracticeInpsNumber,
        variablePracticeInpsNumber
    } = props
    const errors = props.errors || {}
    const classes = useStyles()
    const isNewInps = step2Participation === participationsLookup.INPSNUOVO
    const shouldConfirmInpsValue =
        (inpsServices.find(s => `${s.value}` === `${step2InpsServiceId}`) || {})
            .code !== '2S-NNCOM' && step2InpsServiceId !== EMPTY_INPS_VALUE

    const title = isNewInps
        ? 'Bando INPSieme 2025'
        //: 'Vincitore bando INPSieme 2022'
        : 'Bando INPSieme 2025'

    const description = isNewInps
        ? "Indica l'ISEE 2025 e nel riepilogo della prenotazione troverai la voce 'Costo al netto del contributo', che corrisponde alla differenza tra il totale da pagare e la quota che ti rimborserà l'INPS (se vincitore)."
        : "Indica l'ISEE 2025 e nel riepilogo della prenotazione troverai la voce 'Costo al netto del contributo', che corrisponde alla differenza tra il totale da pagare e la quota che ti rimborserà l'INPS (se vincitore)."


    return (
        <React.Fragment>
            <div className="box-light boxed">
                <Typography variant="h6">{title}</Typography>
                <Typography variant="body1">{description}</Typography>

                <TextField
                    id="inps-contributo"
                    select
                    //label="Contributo inps"
                    error={
                        errors && errors.step2InpsServiceId
                            ? 'Indicazione contributo obbligatoria *'
                            : null
                    }
                    className={classes.textField6 + ' select col-xs-12'}
                    value={step2InpsServiceId}
                    onChange={onEventChange('step2InpsServiceId')}
                    SelectProps={{
                        native: true,
                        MenuProps: {
                            className: classes.menu,
                        },
                    }}
                    //helperText="Indicando l'ISEE calcoleremo in maniera esatta il tuo preventivo personalizzato"
                    margin="normal">
                    <option value="">Seleziona ISEE</option>
                    {inpsServices.map(option => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                    {/* @Agata TODO: reinserita per flusso INPS nuovo */}
                    {/*
          {isNewInps && (
            <option value={EMPTY_INPS_VALUE}>
              Lo comunicherò successivamente
            </option>
          )}
          */}
                </TextField>


                {isNewInps && division === '1' && (
                    <TextField
                        id="classe-anno"
                        select
                        required
                        error={errors && errors.step2Class ? 'Indicazione Classe *' : null}
                        label="Classe frequentata nell'anno scolastico in corso"
                        className={clsx(classes.textField6, 'select ', 'col-xs-12')}
                        value={step2Class || ''}
                        onChange={onEventChange('step2Class')}
                        SelectProps={{
                            MenuProps: {
                                className: classes.menu,
                            },
                        }}
                        margin="normal">
                        <MenuItem value="1^ Classe">1^ Classe</MenuItem>
                        <MenuItem value="2^ Classe">2^ Classe</MenuItem>
                        <MenuItem value="3^ Classe">3^ Classe</MenuItem>
                        <MenuItem value="4^ Classe">4^ Classe</MenuItem>
                        <MenuItem value="5^ Classe">5^ Classe</MenuItem>
                    </TextField>
                )}
                {/*
        <TextField
          id="media-anno"
          //required
          label={
            isNewInps
              ? 'Media finale anno scolastico 2021/2022'
              : 'Media finale anno scolastico 2020/2021'
          }
          className={clsx(classes.textField6, 'select', 'col-xs-12')}
          value={step2Grade || ''}
          onChange={onEventChange('step2Grade')}
          margin="normal"
        />
        */}

        {/*        <div className="col-xs-12 col-sm-12">*/}
        {/*            {shouldConfirmInpsValue && (*/}
        {/*                <FormControl*/}
        {/*                    error={!!errors && !!errors.step2InpsServiceFlag}*/}
        {/*                    component="fieldset">*/}
        {/*                    /!*TODO*!/*/}
        {/*                    <FormControlLabel*/}
        {/*                        control={*/}
        {/*                            <Checkbox*/}
        {/*                                checked={step2InpsServiceFlag}*/}
        {/*                                onChange={onSimpleCheckboxChange('step2InpsServiceFlag')}*/}
        {/*                            />*/}
        {/*                        }*/}
        {/*                        label="Dichiaro che l'importo del contributo comunicato corrisponde a quello a cui ho effettivamente diritto"*/}
        {/*                    />*/}

        {/*                    {!!errors && !!errors.step2InpsServiceFlag && (*/}
        {/*                        <FormHelperText>L'accettazione è obbligatoria</FormHelperText>*/}
        {/*                        // <span className="text-warning">*/}
        {/*                        //   L'accettazione è obbligatoria*/}
        {/*                        // </span>*/}
        {/*                    )}*/}
        {/*                </FormControl>*/}
        {/*            )}*/}
        {/*            <Typography variant="body1">*/}
        {/*                Il dato ISEE indicato verrà confrontato con i dati forniti dall’Ente*/}
        {/*                INPS. <br/>*/}
        {/*                Qualora dovessero essere riscontrate delle differenze tra quanto*/}
        {/*                dichiarato e quanto spettante, le stesse saranno a carico del*/}
        {/*                cliente.*/}
        {/*            </Typography>*/}
        {/*            /!*<p> Ti consigliamo, nel caso in cui non fossi certo dell'importo, di scegliere la selezione: <strong>LO COMUNICHERO' SUCCESSIVAMENTE</strong>.*/}
        {/*</p>*!/*/}
        {/*        </div>*/}
            </div>
            <br/>
            <div className="box-light boxed">
            <Typography variant="h6">Numero pratica Bando Estate Inpsieme (sarà disponibile all'interno della tua area riservata INPS solo dopo aver ricevuto comunicazione sull'idoneità della tua pratica)</Typography>
            <Typography variant="body1">Ti ricordiamo che, se non disponi di questo numero al momento della prenotazione avrai la possibilità di inserirlo successivamente all'interno dell'Area Giocamondo Club. Tale informazione sarà fondamentale per verificare il tuo posizionamento nella graduatoria e gestire al meglio la tua pratica INPS.</Typography>
            {division === '1' ? (
                <TextField
                id="variablePracticeInpsNumber"
                label="EIE2025________"
                className={classes.textField6}
                value={variablePracticeInpsNumber}
                onChange={onEventChange('variablePracticeInpsNumber')}
                helperText={errors && errors.variablePracticeInpsNumber && 'Il codice deve contenere solo numeri e lettere e dev\'essere formato da 15 caratteri'}
                margin="normal"
                /*
                ** commentata validazione il 2024-12-02
                * error={errors && errors.variablePracticeInpsNumber}
                */
            />
            ) : (
                <TextField
                id="variablePracticeInpsNumber"
                label="EIS2025________"
                className={classes.textField6}
                value={variablePracticeInpsNumber}
                onChange={onEventChange('variablePracticeInpsNumber')}
                helperText={errors && errors.variablePracticeInpsNumber && 'Il codice deve contenere solo numeri e lettere e dev\'essere formato da 15 caratteri'}
                margin="normal"
                 /*
                ** commentata validazione il 2024-12-02
                * error={errors && errors.variablePracticeInpsNumber}
                */
            />
            )}
            </div>
            <br/>
            <div className="box-light boxed">
                <Grid container spacing={3} className="panel">
                    <Grid item xs={12} sm={3} className="bonus-wrapper">
                        <Typography variant="h6">Bonus</Typography>
                        {/*<IconGift color="secondary" />*/}
                        <img
                            src="https://giocamondo.it/step2_bonus.png"
                            height={100}
                            alt="bonus"
                        />
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        {step2bonuses.filter(
                            ({bonustype}) => bonustype === bonusTypes.FRATELLO
                        ).length > 0 ? (
                            <React.Fragment>
                                <div className="light-box">
                                    <Typography variant="h5">
                                        Inserisci il nominativo del fratello/sorella che partirà per
                                        un soggiorno Giocamondo
                                        {/* {division === '1' ? ' Giocamondo Study ' : ' Giocamondo '} */}
                                        {/* nel 2020 */}
                                    </Typography>
                                    <p>
                                        Lo sconto <strong>Bonus Fratelli</strong> di € 25 verrà applicato alle
                                        prenotazioni di ciascun partecipante in
                                        cui verrà selezionato.
                                    </p>
                                    <br/>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={step2BonusRequested}
                                                onChange={onBonusRequest(bonusTypes.FRATELLO)}
                                            />
                                        }
                                        label="Voglio usufruire del Bonus Fratelli"
                                    />
                                    <br/>
                                    <TextField
                                        id="cognome"
                                        label="Cognome"
                                        className={classes.textField}
                                        value={step2BonusSurname}
                                        onChange={onEventChange('step2BonusSurname')}
                                        margin="normal"
                                    />
                                    <TextField
                                        id="nome"
                                        label="Nome"
                                        className={classes.textField}
                                        value={step2BonusName}
                                        onChange={onEventChange('step2BonusName')}
                                        margin="normal"
                                    />
                                    <p>
                                    <small>* I bonus selezionati saranno soggetti a verifica</small>
                                    </p>
                                </div>
                            </React.Fragment>
                        ) : (
                            'Nessun bonus disponibile su questo pacchetto, clicca avanti per proseguire.'
                        )}
                    </Grid>
                </Grid>
            </div>
            <br/>
            
            {/* Inserito modulo richiesta Età e Classe */}

            <Grid item xs={12}>
            <div className="box-light boxed">
            <Typography variant="h6">Età e Classe frequentata</Typography>
            <Typography variant="body1">Per una migliore organizzazione dei gruppi di partecipanti, sarebbe utile ricevere indicazione sull'età del partecipante e la classe frequentata.</Typography>
            <TextField
                id="s2eta_partecipant"
                label="Età del partecipante"
                className={classes.textField6}
                value=''
                onChange=''
                //helperText=
                margin="normal"
            />
            {division === '1' ? (
                <TextField
                id="s2classe-anno"
                select
                //required
                error=''
                label="Classe frequentata nell'anno scolastico in corso"
                className={clsx(classes.textField6, 'select ', 'col-xs-12')}
                value=''
                onChange=''
                SelectProps={{
                    MenuProps: {
                        className: classes.menu,
                    },
                }}
                margin="normal">
                <MenuItem value="1^ Superiore">1^ Superiore</MenuItem>
                <MenuItem value="2^ Superiore">2^ Superiore</MenuItem>
                <MenuItem value="3^ Superiore">3^ Superiore</MenuItem>
                <MenuItem value="4^ Superiore">4^ Superiore</MenuItem>
                <MenuItem value="5^ Superiore">5^ Superiore</MenuItem>
            </TextField>
            ) : (
                <TextField
                id="s2classe-anno"
                select
                //required
                error=''
                label="Classe frequentata nell'anno scolastico in corso"
                className={clsx(classes.textField6, 'select ', 'col-xs-12')}
                value=''
                onChange=''
                SelectProps={{
                    MenuProps: {
                        className: classes.menu,
                    },
                }}
                margin="normal">
                <MenuItem value="1^ Elementare">1^ Elementare</MenuItem>
                <MenuItem value="2^ Elementare">2^ Elementare</MenuItem>
                <MenuItem value="3^ Elementare">3^ Elementare</MenuItem>
                <MenuItem value="4^ Elementare">4^ Elementare</MenuItem>
                <MenuItem value="5^ Elementare">5^ Elementare</MenuItem>
                <MenuItem value="1^ Media">1^ Media</MenuItem>
                <MenuItem value="2^ Media">2^ Media</MenuItem>
                <MenuItem value="3^ Media">3^ Media</MenuItem>
            </TextField>
            )}
            </div>
            </Grid>


            {/**
             * Modifica Agata del 12.12.2024
             * Verifica se la data di nascita del figlio è presente E l'età calcolata è almeno 18 anni */}
            {(step1ChildBirthDate && 
                calculateAge(step1ChildBirthDate) >= 18) && (
                <Grid item xs={12}>
                    <div className="alert alert-primary" role="alert">
                        <FormControl
                            component="fieldset"
                            className={classes.formControl}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={step2QCodiceCultura}
                                        onChange={onSimpleCheckboxChange('step2QCodiceCultura')}
                                    />
                                }
                                label="Sei in possesso di bonus cultura e vuoi utilizzarli su questa prenotazione?"
                            />
                        </FormControl>
                    </div>
                </Grid>
            )}
            <br/>
            <Coupon onChange={onEventChange('step2Coupon')} value={step2Coupon}/>
            <Voucher
                step1ChildFiscalCode={step1ChildFiscalCode}
                onChange={onEventChange}
                value={step2VoucherCode}
                values={step2VoucherCodes}
            />
        </React.Fragment>
    )
}

StepBonusInpsieme.propTypes = {
    inpsServices: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any.isRequired,
            label: PropTypes.string.isRequired,
        })
    ),
    participations: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any.isRequired,
            label: PropTypes.string.isRequired,
        })
    ),
    step2bonuses: PropTypes.arrayOf(
        PropTypes.shape({
            bonustype: PropTypes.string.isRequired,
        })
    ),
    step2Participation: PropTypes.string,
    step2InpsServiceId: PropTypes.string,
    step2Grade: PropTypes.string,
    step2Class: PropTypes.string,
    step2InpsServiceFlag: PropTypes.bool,
    step2BonusRequested: PropTypes.bool,
    step2BonusSurname: PropTypes.string,
    step2BonusName: PropTypes.string,
    step2Coupon: PropTypes.string,
    onEventChange: PropTypes.func.isRequired,
    onSimpleCheckboxChange: PropTypes.func.isRequired,
    onBonusRequest: PropTypes.func.isRequired,
}
